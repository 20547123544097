class User {
  idinfo: [];
  skills: [];
  experiences: [];
  aboutme: string;
  constructor(data) {
    this.idinfo = data.idinfo;
    this.skills = data.skills;
    this.experiences = data.experiences;
    this.aboutme = data.aboutme;
  }
}
export default User;
