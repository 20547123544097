export function PreviewCard({
  title,
  description,
  imageLink,
  link,
  style = {},
}) {
  return (
    <>
      <figure
        className="project-preview"
        onMouseOver={(e) => {
          handleHover(e, title, description, imageLink, link);
        }}
        onMouseOut={handleHover}
      >
        <div className="project-image">
          <img style={style} src={imageLink}></img>
        </div>
        <figcaption>
          <p className="g-c-text">{description}</p>
        </figcaption>
        <a href={link} target="_blank" rel="noopener noreferrer"></a>
      </figure>
    </>
  );
}

function handleHover(e, title, description, imageLink, link) {
  if (e.type === "mouseover") {
    const activeItem = document.querySelector(".active-item");
    const img = document.getElementById("active-img");
    const projectlink = document.getElementById("learn-more-btn");

    //console.log("Mouse is still on the element");
    if (e.target.classList.contains("on")) return;

    e.target.classList.add("on");
    activeItem.style.opacity = 0.35;
    img.style.opacity = 0.35;
    setTimeout(() => {
      activeItem.children[0].innerText = title;
      activeItem.children[1].innerText = description;
      img.src = imageLink;
      projectlink.href = link;
      img.style.opacity = 1;
      activeItem.style.opacity = 1;
    }, 350);
  } else if (e.type === "mouseout") {
    //console.log("Mouse is no longer on the element");
    if (!e.target.classList.contains("on")) return;
    e.target.classList.remove("on");
  }
}

export function MoviePlayer() {
  let movieUrl = "tt0133093";
  return (
    <>
      <div style={{ width: "90%", maxWidth: "500px", height: "300px" }}>
        <iframe
          style={{ width: "100%", height: "100%", borderRadius: "50px" }}
          allowFullScreen
          src={"https://vidsrc.to/embed/movie/" + movieUrl}
        ></iframe>
      </div>
      <p>
        This player is powered by a third party company. Please beware of the
        Ads
      </p>
    </>
  );
}

export function MovieSearchLink() {
  return (
    <PreviewCard
      description="This website uses TVMaze API to search for movies"
      link="https://amantes30.github.io/MovieSearch/"
      imageLink="/image/moviesearch.png"
    />
  );
}
