function Header({ getData }) {
  return (
    <header>
      <button className="link en" onClick={() => getData("en")}>
        EN
      </button>
      <br />

      <button className="link cn" onClick={() => getData("cn")}>
        CN
      </button>
    </header>
  );
}
export default Header;
