import { v4 as uuidv4 } from "uuid";
import { PreviewCard } from "./Projects/Projects";
import Message from "../models/message.ts";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import LoadScreen from "./LoadScreen.js";

export function Item({ itemInfo, type }) {
  let item = <></>;

  switch (type) {
    case "aboutme":
      item = <AboutMe itemInfo={itemInfo} />;
      break;
    case "projects":
      item = <Projects itemInfo={itemInfo} />;
      break;
    case "experience":
      item = <Experience itemInfo={itemInfo} />;
      break;
    case "skills":
      item = <Skills itemInfo={itemInfo} />;
      break;
    case "chatbox":
      item = <ChatBox itemInfo={itemInfo} />;
      break;
    default:
      break;
  }
  return item;
}
function ChatBox({ itemInfo }) {
  const socket = itemInfo.socket;
  return (
    <>
    
      <div className="chat closed">
        <div className="chat-header">
          <div className="chat-logo">
            <img src="https://amantes30.github.io/icons/SVG/logo(new).svg"></img>
          </div>
          <button className="chat-close" onClick={toggleChat}>
            <img src="/close.svg" />
          </button>
        </div>
        <div className="chat-body">
          <div className="chat-message">
            <div className="chat-message-content">
              <ul id="messages">
                <li className="msg"></li>
              </ul>
            </div>

            <form
              id="chat-form"
              style={{ display: "none" }}
              onSubmit={(e) => {
                e.preventDefault();
                if (!socket.connected) {
                  alert("Please input your email first");
                  return;
                }
                const msg = {
                  message: e.target.message.value,
                  sender: window.localStorage.getItem("email"),
                  timeStamp: new Date().toISOString(),
                  roomId: window.localStorage.getItem("roomID"),
                };

                socket.emit("message", new Message(msg));
              }}
            >
              {itemInfo.site === "en" ? (
                <>
                  <input
                    type="text"
                    placeholder="say what?"
                    className="form-control"
                    name="message"
                    autoComplete="off"
                  />

                  <button type="submit" id="send-btn">
                    Send
                  </button>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    placeholder="说什么？"
                    className="form-control"
                    name="message"
                    autoComplete="off"
                  />
                  <button type="submit" id="send-btn">
                    发送
                  </button>
                </>
              )}
            </form>

            <form
              id="hidden-chat-form"
              onSubmit={async (e) => {
                e.preventDefault();

                const input = document.getElementById("email-input");
                input.classList.toggle("loading");
                input.readOnly = true;
                itemInfo.setLoadStatus(true);
                itemInfo.setMessage("Connecting to the server");
                window.localStorage.setItem("email", e.target.email.value);

                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/login?email=${e.target.email.value}`
                  )
                  .then((res) => {
                    console.log(res.data);
                    if (socket.connected) {
                      socket.emit("setuser", res.data);

                      // welcome message
                      const messages = document.getElementById("messages");
                      const li = document.createElement("li");
                      const p = document.createElement("p");
                      p.textContent = `Welcome to the chat. Please give me a few seconds to connect to the server.\n Feel free to put in your inquiry, I can read it when I'm online.`;
                      li.className = "msg";
                      li.appendChild(p);
                      messages.appendChild(li);
                      e.target.reset();
                      e.target.style = "display:none";
                      document.getElementById("chat-form").style =
                        "display:flex";
                    } else {
                      alert(
                        "Server is down, This feature is not available at the moment"
                      );
                    }
                  })
                  .catch((err) => {
                    alert("Error connecting to the server");
                  });
              }}
            >
              {itemInfo.site === "en" ? (
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  name="email"
                  autoComplete="off"
                  required
                  id="email-input"
                />
              ) : (
                <input
                  type="email"
                  placeholder="电子邮件"
                  className="form-control"
                  name="email"
                  autoComplete="off"
                  required
                  id="email-input"
                />
              )}

              <button type="submit" id="send-btn">
                set
              </button>
            </form>
          </div>
        </div>
      </div>
      <button className="chatbox-toggle" onClick={toggleChat}>
        {itemInfo.site === "en" ? (
          <p style={{ margin: 0 }}>Chat with me</p>
        ) : (
          <p style={{ margin: 0 }}>与我聊天</p>
        )}
      </button>
    </>
  );
}
function Experience({ itemInfo }) {
  var listt = [];
  itemInfo.user.experiences.map((experience) => {
    return listt.push(
      <li key={uuidv4()}>
        <img src={experience.logoUrl} />
        <div>
          <a
            style={{ color: "white", textDecoration: "none" }}
            href={"https://" + experience.link}
          >
            <h3>{experience.organization}</h3>
          </a>
          <p>{experience["job"]}</p>
          {experience.description}
        </div>
      </li>
    );
  });

  return (
    <div className="item experience-item">
      {itemInfo.site === "en" ? (
        <h2 className="title g-c-text">Internship</h2>
      ) : (
        <h2 className="title g-c-text">实习</h2>
      )}
      <hr />
      <ul>{listt}</ul>
    </div>
  );
}
function AboutMe({ itemInfo }) {
  const [txt, setTxt] = useState("_");
  let inp = [];
  const text = "Ethiopian";
  const text_to_list = text.split("");
  useEffect(() => {
    let counter = 0;
    setInterval(() => {
      if (counter < text_to_list.length) {
        inp.push(text_to_list[counter]);
        setTxt(inp.join(""));
        counter++;
      }
    }, 250);
  }, []);
  return (
    <div className="item aboutme">
      <div className="pfp" style={{ animationTimeline: "view(10% 90%)" }}>
        <img
          style={{ objectFit: "cover", pointerEvents: "none" }}
          width={"100%"}
          height={"100%"}
          alt="pfp"
          src="/image/pfp.png"
        ></img>
      </div>
      <h1 className="g-c-text" id="name">
        {itemInfo.user.idinfo[0].firstname}
      </h1>
      <h2
        className="g-c-text"
        id="degree"
        onMouseEnter={() => {
          document.getElementById("degree-img").style.scale = "1";
        }}
        onMouseLeave={() => {
          document.getElementById("degree-img").style.scale = "0";
        }}
      >
        {">" + itemInfo.user.idinfo[0].profession}
        <div id="degree-img">
          <img src="/image/degree.png" />
        </div>
      </h2>

      <h2 className="g-c-text">
        {">" + txt}
        <span className="blink-underscore">_</span>
      </h2>
    </div>
  );
}
function Skills({ itemInfo }) {
  var list = [];
  var list2 = [];
  var list3 = [];
  itemInfo.user.skills.forEach((skill) => {
    switch (skill.type) {
      case "lang":
        list.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      case "framework":
        list2.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      case "tool":
        list3.push(
          <li key={uuidv4()} className={skill.text + "logo"}>
            <a href={skill.link} key={uuidv4()}>
              <img
                className="skill-icons shadowed"
                src={skill.icon}
                alt={skill.text}
              ></img>
            </a>
          </li>
        );
        break;
      default:
        break;
    }
  });
  return (
    <div className="item skills" style={{ animationTimeline: "view(70% 10%)" }}>
      <div
        className="skills-animoji"
        style={{ animationTimeline: "view(70% 20%)" }}
      >
        <img src="/image/animoji.png"></img>
      </div>

      <section className="skill-section">
        {itemInfo.site === "en" ? (
          <h2 className="g-c-text">Programming languages</h2>
        ) : (
          <h2 className="g-c-text">编程语言</h2>
        )}

        <ul className="skillitemist scrollable">
          {list}
          {list}
        </ul>
      </section>

      <section className="skill-section">
        <h2 className="g-c-text">Frameworks</h2>
        <ul className="skillitemist">{list2}</ul>
      </section>

      <section className="skill-section">
        {itemInfo.site === "en" ? (
          <h2 className="g-c-text">Tools</h2>
        ) : (
          <h2 className="g-c-text">工具</h2>
        )}

        <ul className="skillitemist scrollable">
          {list3}
          {list3}
        </ul>
      </section>

      {/* <p>3D modelling (3ds Max)</p> */}
    </div>
  );
}
function Projects({ itemInfo }) {
  console.log(itemInfo);
  const projects = [
    {
      title: "Photos",
      description:
        "This website integrates Unsplash's API to retrieve client's pictures and showcase in the portfolio",
      imagelink: "/image/yosias G.png",
      link: "https://yozz1.pages.dev",
    },
    {
      title: "Text-to-Image",
      description:
        "This website uses SD-XL 1.0-base Model Card to convert text to image",
      imagelink: "/image/text-to-img(thumb).jpg",
      link: "https://amantes30.github.io/text-to-image/",
    },
    {
      title: "Translator",
      description:
        "Translator website that uses Google's API to translate text",
      imagelink: "/image/translator.png",
      link: "https://translator-cj1.pages.dev",
    },

    {
      title: "Weather Site",
      description:
        "This website uses 7Timer API to retrieve 7 day weather forecast for different cities",
      imagelink: "/image/weathersite.png",
      link: "https://weather-112.pages.dev",
    },
  ];
  return (
    <div className="item projects">
      {itemInfo.site === "en" ? (
        <h2 className="title g-c-text">Projects</h2>
      ) : (
        <h2 className="title g-c-text">项目</h2>
      )}

      <hr />

      <ul className="project-list">
        <div className="active-item">
          <h1 style={{fontSize: '7em'}}>Photos</h1>
          <p>
            This website integrates Unsplash's API to retrieve client's pictures
            and showcase in the portfolio
          </p>
          <a id="learn-more-btn">
            <p style={{ margin: 0 }}>Visit Site</p>
          </a>
        </div>
        <img id="active-img" src="/image/yosias G.png" />
        {projects.map((project) => {
          return (
            <li key={uuidv4()}>
              <PreviewCard
                title={project.title}
                description={project.description}
                link={project.link}
                imageLink={project.imagelink}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
function toggleChat(e) {
  e.preventDefault();
  const chat = document.querySelector(".chat");
  const chatboxToggle = document.querySelector(".chatbox-toggle");
  const messages = document.getElementById("messages");
  const inputField = document.getElementById("email-input");
  if (chat.classList.contains("closed")) {
    chatboxToggle.style = "display:none";
    chat.classList.toggle("closed", false);
    messages.scrollTop = messages.scrollHeight;
    inputField.focus();
  } else {
    chat.classList.toggle("closed", true);
    chatboxToggle.style = "display:flex, text-align:center";
  }
}
