import { useEffect } from "react";

export default function LoadScreen({ message }) {
  const text_to_list = message.split("");
  console.log(message);
  useEffect(() => {
    let counter = 0;
    setInterval(() => {
      if (counter < text_to_list.length && message !== "") {
        if (document.querySelector(".load-wrapper p") !== null) {
          document.querySelector(".load-wrapper p").textContent +=
            text_to_list[counter];
          counter++;
        }
      }
    }, 50);
  }, []);
  const pStyle = {
    maxWidth: "350px",
    textAlign: "center",
  };
  return (
    <div className="load-wrapper">
      {/* <div className="spinner-grow text-primary" role="status">
        <span className="visually-hidden">Loading</span>
      </div> */}
      <p style={pStyle}></p>
    </div>
  );
}
