class Message{
    message: string;
    sender: string;
    timeStamp: Date;
    roomId: string;
    constructor(data){
        this.message = data.message;
        this.sender = data.sender;
        this.timeStamp = data.timeStamp;
        this.roomId = data.roomId;
    }
}
export default Message;
