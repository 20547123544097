import { useEffect, useState } from "react";
import axios from "axios";
import { io } from "socket.io-client";

import ItemList from "./components/ItemList.js";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";

import "./styles/style.css";
import "./styles/App.css";
import LoadScreen from "./components/LoadScreen.js";
import User from "./models/user.ts";

import CryptoJS from "crypto-js";
function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoadStatus] = useState(true);
  const [message, setMessage] = useState("Getting site data");
  const [socket, setSocket] = useState(null);
  const [site, setSite] = useState("en");
  const getData = async (lang) => {
    setLoadStatus(true);

    function getdevicetype() {
      if (navigator.userAgent.match(/mobile/i)) {
        return "Mobile";
      } else if (navigator.userAgent.match(/iPad|Android|Touch/i)) {
        return "Tablet";
      } else {
        return "Desktop";
      }
    }
    console.log(getdevicetype());
    axios
      .get(`${process.env.REACT_APP_API_URL}/my-profile`, {
        params: { lang: lang, device: getdevicetype() },
      })

      .then(async (res) => {
        if (getdevicetype() === "Desktop") {
          const bytes = CryptoJS.AES.decrypt(
            res.data,
            process.env.REACT_APP_CRYP_SECRET
          );
          const aman = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))[0];
          setLoadStatus(false);

          // console.log(decryptedData);
          setUser(new User(aman));
        } else {
          setUser(new User(res.data[0]));
          setLoadStatus(false);
          setMessage("");
        }
        //decrypt the data

        // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (lang === "cn") {
          document.querySelector(".link.en").classList.toggle("active", false);
          document.querySelector(".link.cn").classList.toggle("active", true);
        } else {
          document.querySelector(".link.en").classList.toggle("active", true);
          document.querySelector(".link.cn").classList.toggle("active", false);
        }
        setSite(lang);
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    window.addEventListener("", (e) => {
      console.log(e);
    });
    // setInterval(detectDevTools, 1000)
    setSite("en");
    initSocket();
  }, []);

  async function initSocket() {
    window.CredentialsContainer;
    await navigator.setAppBadge(1).then((res) => {
      console.log(res);
    });
    const notif = new Notification("ola");

    // set headers for the request
    const socket = io(process.env.REACT_APP_SERVER_URL, {
      Headers: {
        "ngrok-skip-browser-warning": "1",
      },
      extraHeaders: {
        "ngrok-skip-browser-warning": "1",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "1",
        },
      },
    });
    socket.on("connect", async () => {
      //getData();
      getData(site);

      await axios
        .get(`${process.env.REACT_APP_API_URL}/connect`)
        .then((res) => {
          console.log(res.data);
        });
    });
    socket.on("message", (data) => {
      MakeMessageHtml(data);
    });
    socket.on("setuser", async (data) => {
      console.log("setting user");
      window.localStorage.setItem("email", data.email);
      window.localStorage.setItem("roomID", data.roomId);
      console.log(data);
      await axios
        .get(`${process.env.REACT_APP_API_URL}/history`, {
          params: { roomId: data.roomId, email: data.email },
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((data) => {
            MakeMessageHtml(data);
          });
        })
        .catch((err) => {
          console.log(err);
        });
      setLoadStatus(false);
      setMessage("");
    });
    socket.on("admin-joined-room", () => {
      const chatForm = document.getElementById("chat-form");
      const messages = document.getElementById("messages");
      const li = document.createElement("li");
      const p = document.createElement("p");
      p.textContent = `Aman has joined you!`;

      li.appendChild(p);
      messages.appendChild(li);

      chatForm.reset();

      messages.scrollTop = messages.scrollHeight;
    });
    socket.on("disconnect", (socket) => {});
    setSocket(socket);
  }
  return (
    <>
      <Header getData={getData} />
      {user ? (
        <ItemList
          itemInfo={{
            user: user,
            socket: socket,
            setLoadStatus: setLoadStatus,
            setMessage: setMessage,
            site: site,
          }}
        />
      ) : (
        ""
      )}
      <Footer />
      {loading && <LoadScreen message={message} />}
    </>
  );
}

export default App;

function MakeMessageHtml(data) {
  const chatForm = document.getElementById("chat-form");
  const messages = document.getElementById("messages");
  const li = document.createElement("li");
  const p = document.createElement("p");
  p.textContent = data.message;
  li.className = data.sender === "admin" ? "msg" : "msg i";
  li.appendChild(p);
  messages.appendChild(li);
  chatForm.reset();
  messages.scrollTop = messages.scrollHeight;
}
function triggerProtection() {
  const overlay = document.createElement("div");
  overlay.style.position = "fixed";
  overlay.style.width = "100dvw";
  overlay.style.height = "100dvh";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.background = "black";
  overlay.style.zIndex = "500";
  overlay.innerHTML = "<h1>Please close developer tools</h1>";
  document.getElementById("root").remove();
  document.body.appendChild(overlay);
  console.log = console.warn = console.error = () => {};
  debugger;
}
// function detectStringAnomalies(){
//   const check = / ./;
//   check.toString() = () => "DevTools";
//   console.log(check)
//   if(console.log.toString().includes("DevTools")){
//     triggerProtection();
//   }
// }
function detectDevTools() {
  const start = performance.now();
  debugger;
  const end = performance.now();
  if (end - start > 100) {
    console.log("Developer tools opened");
    triggerProtection();
  }
}
